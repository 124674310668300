import React from "react";
import Collapsible from "react-collapsible"; // Import Collapsible from your collapsible library
import { IoMdArrowDropdownCircle, IoMdArrowDropupCircle } from "react-icons/io"; // Import both icons

const CollapsibleSection = ({ header, content }) => {
  return (
    <div className="w-full font-gnarly-semibold bg-red text-black dark:text-[#cfcfcf] cursor-pointer border-t border-black dark:border-[#cfcfcf] py-5 md:py-10 px-5 md:px-20">
      <Collapsible
        transitionTime={200}
        trigger={
          <div className="flex items-center justify-between text-xl md:text-4xl">
            <span>{header}</span>
            <IoMdArrowDropdownCircle className="text-xl md:text-4xl hover:scale-110" />
          </div>
        }
        triggerWhenOpen={
          <div className="flex items-center justify-between text-xl md:text-4xl">
            <span>{header}</span>
            <IoMdArrowDropupCircle className="text-xl md:text-4xl hover:scale-110" />
          </div>
        }
      >
        <div className="flex justify-center text-lg font-gnarly-regular w-full lg:w-1/2 mx-auto pt-10">
          {content}
        </div>
      </Collapsible>
    </div>
  );
};

export default CollapsibleSection;
