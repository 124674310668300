import React from "react";

const SplineMockup = () => {
  return (
    <div className="w-full h-full">
      <iframe
        src="https://my.spline.design/iphoneprocopy-fe6f7d1f6282cd583d3dd8f3d7d85f61/"
        width="100%"
        height="100%"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
};

export default SplineMockup;
