import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import { isMobile } from "react-device-detect";
import { FaQuestionCircle } from "react-icons/fa";

// Example population data (you can extend this with real data)
const populationData = {
  FRA: 67081000, // France
  DEU: 83166711, // Germany
  ITA: 60244639, // Italy
  ESP: 47332614, // Spain
  // Add more countries here...
};

const MapChart = () => {
  // State to track hovered country info
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const scale = isMobile ? 1000 : 380;
  const offSet = isMobile ? 0 : -24;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="relative">
      <div
        className="absolute p-1 bg-gray-800 text-white  rounded text-sm pointer-events-none z-50"
        style={{
          top: `${tooltipPosition.y}px`,
          left: `${tooltipPosition.x}px`,
          display: tooltipContent ? "block" : "none",
        }}
      >
        {tooltipContent}
      </div>
      <div
        className="absolute z-50 top-0 right-0 text-[#cfcfcf] flex items-center space-x-2 p-5"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && (
          <div className="bg-gray-800 text-white px-3 py-1 rounded text-sm">
            {isMobile
              ? "Click on the countries to see the Total Addressable Market of that country."
              : "Hover above the countries to see the Total Addressable Market of that country."}
          </div>
        )}
        <FaQuestionCircle className="text-xl  md:text-3xl" />
      </div>

      <div className="flex-1 relative max-h-[50vh] min-h-[33vh] overflow-clip ">
        {/* Tooltip */}
        <ComposableMap
          projection="geoAzimuthalEqualArea"
          projectionConfig={{
            rotate: [-10.0, -52.0, 0],
            center: [-0, offSet],
            scale: scale,
          }}
        >
          <Geographies // USE ZOOMABLEGROUP when zoom is desire
            geography="/features.json"
            fill="#D6D6DA"
            stroke="#FFFFFF"
            strokeWidth={0.2}
          >
            {({ geographies }) =>
              geographies.map((geo) => {
                const countryCode = geo.id; // Assuming ISO_A3 is the code in your topojson
                const population = populationData[countryCode] || countryCode;

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={(e) => {
                      setTooltipContent(
                        `Total Addressable Market: ${population}`
                      );
                      if (!isMobile) {
                        setTooltipPosition({
                          x: e.clientX,
                          y: e.clientY,
                        });
                      }
                    }}
                    onMouseMove={(e) => {
                      if (!isMobile) {
                        setTooltipPosition({
                          x: e.clientX,
                          y: e.clientY,
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      setTooltipContent("");
                    }}
                    style={{
                      default: {
                        fill: "#505050",
                        outline: "none",
                      },
                      hover: {
                        fill: "#F53",
                        outline: "none",
                      },
                      pressed: {
                        fill: "#E42",
                        outline: "none",
                      },
                    }}
                    className="transition-colors duration-300 ease-in-out"
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
      </div>
    </div>
  );
};

export default MapChart;
