import React, { useState } from "react";
import { ThemeProvider } from "./ThemeContext"; // Import ThemeProvider
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import CollapsibleSection from "./CollapsibleSection";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import Map from "./Map";
import Header from "./HeaderInvestors";
import Footer from "./Footer";

function ForInvestors() {
  const { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // New state to toggle password visibility
  const [username, setUsername] = useState("");

  const { i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    Cookies.set("language", lang, { sameSite: "None", secure: true });
  };
  /* 
  const correctPassword = "yourPassword"; // Set your desired password here

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password!");
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (!isAuthenticated) {
    return (
      <ThemeProvider>
        <div className="flex flex-col items-center min-h-screen bg-[#f7f4ed] dark:bg-[#202020]">
          <Header changeLanguage={changeLanguage} />
          <div className="flex w-full justify-center items-center flex-grow">
            <div className="max-w-md bg-white dark:bg-black p-8 rounded-xl shadow-lg">
              <h2 className="text-2xl mb-4 text-center font-gnarly-regular text-black dark:text-[#cfcfcf]">
                Enter Access Code
              </h2>
              <form onSubmit={handlePasswordSubmit} className="flex flex-col">
                              <div className="relative flex border rounded-lg mb-5 bg-white">
                  <input
                    type="text"
                    className="p-3 rounded-lg outline-none"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)} // Add state handler for username
                  />
                </div> 

                <div className="relative flex border rounded-lg mb-5 bg-white">
                  <input
                    type={showPassword ? "text" : "password"} // Toggle between text and password
                    className="p-3 rounded-lg outline-none"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={toggleShowPassword}
                    className="items-center w-7 text-gray-600 dark:text-[#cfcfcf]"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>

                <a
                  className="cursor-pointer mt-4 text-center dark:text-[#cfcfcf] hover:underline"
                  onClick={handlePasswordSubmit} // Handle form submission
                >
                  Submit
                </a>
              </form>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
 */
  return (
    <ThemeProvider>
      <div className="flex flex-col w-full min-h-screen bg-[#f7f4ed] dark:bg-[#202020]">
        <Header changeLanguage={changeLanguage} />

        <Map />

        <div className=" flex flex-col items-center justify-center">
          <CollapsibleSection
            header={t("investorsPage.overview")}
            content={
              <div className="text-gray-700 dark:text-gray-300 leading-relaxed space-y-4">
                <p>{t("investorsPage.market_opportunity.paragraph1")}</p>
                <p>{t("investorsPage.market_opportunity.paragraph2")}</p>
              </div>
            }
          />
          <CollapsibleSection
            header={t("investorsPage.competitors")}
            content={
              <div className="text-gray-700 dark:text-gray-300 leading-relaxed space-y-4">
                <p>
                  <strong>
                    {t("investorsPage.competitors_section.title")}
                  </strong>
                  <br />
                  {t("investorsPage.competitors_section.list")}
                </p>

                <p>{t("investorsPage.intro_text")}</p>

                <ul className="list-decimal list-inside pl-5 space-y-3">
                  <li>
                    <strong>
                      {t("investorsPage.key_points.point1.title")}
                    </strong>
                    <p>{t("investorsPage.key_points.point1.description")}</p>
                    <p>{t("investorsPage.key_points.point1.details1")}</p>
                    <p>{t("investorsPage.key_points.point1.details2")}</p>
                  </li>
                  <li>
                    <strong>
                      {t("investorsPage.key_points.point2.title")}
                    </strong>
                    <p>{t("investorsPage.key_points.point2.description")}</p>
                  </li>
                  <li>
                    <strong>
                      {t("investorsPage.key_points.point3.title")}
                    </strong>
                  </li>
                  <li>
                    <strong>
                      {t("investorsPage.key_points.point4.title")}
                    </strong>
                  </li>
                  <li>
                    <strong>
                      {t("investorsPage.key_points.point5.title")}
                    </strong>
                    <p>{t("investorsPage.key_points.point5.description")}</p>
                  </li>
                </ul>
              </div>
            }
          />

          <CollapsibleSection
            header={t("investorsPage.marketing")}
            content={
              <div className="flex-row">
              <p className="text-gray-700 dark:text-gray-300"></p>
              <p>{t('investorsPage.application_process.intro')}</p>
              <br />
              <ul className="list-disc list-inside pl-5">
                <li>{t('investorsPage.application_process.strategies.strategy1')}</li>
                <li>{t('investorsPage.application_process.strategies.strategy2')}</li>
                <li>{t('investorsPage.application_process.strategies.strategy3')}</li>
              </ul>
            </div>
            }
          />
        </div>
        <div className="w-full">
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default ForInvestors;
