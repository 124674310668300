import React from "react";
import { useTranslation } from "react-i18next";

function Mission() {
  const { t } = useTranslation();

  return (
    <div className="flex-col p-10 pb-10 xl:pb-36 dark:text-[#cfcfcf]">
      <h1 className="text-3xl font-gnarly-semibold mb-6">
        {t("mission.title")}
      </h1>
      <ul className="list list-inside space-y-20 font-gnarly-semibold text-2xl">
        <li>{t("mission.point1")}</li>
        <li>{t("mission.point2")}</li>
        <li>{t("mission.point3")}</li>
      </ul>
    </div>
  );
}

export default Mission;
